var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var dispatchTrackingEventGA4 = function (data) {
    if (Object.keys(data).length > 0) {
        var event = data.event, eventData = __rest(data, ["event"]);
        (window.dataLayer = window.dataLayer || []).push({
            event: event,
            event_data: eventData,
            _clear: true,
        });
    }
};
