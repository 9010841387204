var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import { fadeInOpacity, spin } from './keyframes';
var FADE_IN_DURATION = 300;
var FADE_IN_TIMING_FN = 'ease-in';
var SPIN_DURATION = 900;
var SPIN_TIMING_FN = 'linear';
var getAnimationCSS = function (_a) {
    var keyframes = _a.keyframes, iterationCount = _a.iterationCount, timingFn = _a.timingFn, duration = _a.duration;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  animation-name: ", ";\n  animation-iteration-count: ", ";\n  animation-timing-function: ", ";\n  animation-duration: ", "ms;\n"], ["\n  animation-name: ", ";\n  animation-iteration-count: ", ";\n  animation-timing-function: ", ";\n  animation-duration: ", "ms;\n"])), keyframes, iterationCount, timingFn, duration);
};
export var fadeInAnimation = getAnimationCSS({
    keyframes: fadeInOpacity,
    iterationCount: 1,
    timingFn: FADE_IN_TIMING_FN,
    duration: FADE_IN_DURATION,
});
export var spinAnimation = getAnimationCSS({
    keyframes: spin,
    iterationCount: 'infinite',
    timingFn: SPIN_TIMING_FN,
    duration: SPIN_DURATION,
});
var templateObject_1;
