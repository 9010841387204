var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, justifyContent, space, size as systemSize, themeGet } from 'styled-system';
import { spinAnimation } from '../theme/animation';
import Icon from './Icon';
var SpinnerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, justifyContent, space);
var wrapperStyles = { display: 'flex', justifyContent: 'center' };
var SpinnerIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n  stroke: ", ";\n"], ["\n  ", ";\n  ", "\n  stroke: ", ";\n"])), spinAnimation, systemSize, function (_a) {
    var strokeColor = _a.strokeColor;
    return themeGet("colors.".concat(strokeColor));
});
var Spinner = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, strokeColor = _a.strokeColor, margin = _a.margin, className = _a.className;
    return (React.createElement(SpinnerWrapper, __assign({ className: className, "data-testid": "spinner", role: "progressbar", margin: margin }, wrapperStyles),
        React.createElement(SpinnerIcon, { name: "spinner", size: size, strokeColor: strokeColor })));
};
export default Spinner;
var templateObject_1, templateObject_2;
