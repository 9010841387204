var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { height } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { textDecoration } from '../../theme/system-utilities';
import { dispatchTrackingEventGA4 } from '../../tracking/dispatchTrackingEventGA4';
import { Element } from '../../types/tracking';
import Spinner from '../Spinner';
import { ButtonBase, SPINNER_SIZE } from './Base';
import { ButtonBlack } from './Black';
import { ButtonGreen } from './Green';
import { ButtonPrimary } from './Primary';
import { ButtonSecondary, ButtonSecondary_V2 } from './Secondary';
var trackButtonClick = function (tracking) {
    var _a;
    if (tracking) {
        var _b = tracking.element, element = _b === void 0 ? Element.Button : _b, text = tracking.text, eventName = tracking.eventName, placement = tracking.placement, provider = tracking.provider, interaction = tracking.interaction, title = tracking.title, restTrackingParams = __rest(tracking, ["element", "text", "eventName", "placement", "provider", "interaction", "title"]);
        dispatchTrackingEventGA4(__assign(__assign(__assign(__assign(__assign((_a = { event: eventName, element: element }, _a["".concat(element, "_text")] = text, _a), (placement && { placement: placement })), (provider && { provider: provider })), (interaction && { interaction: interaction })), (title && { title: title })), restTrackingParams));
    }
};
var getStyledButton = function (kind) {
    switch (kind) {
        case 'primary':
            return ButtonPrimary;
        case 'secondary':
            return ButtonSecondary;
        case 'secondary-v2':
            return ButtonSecondary_V2;
        case 'black':
            return ButtonBlack;
        case 'green':
            return ButtonGreen;
        default:
            return ButtonBase;
    }
};
var StyledSpinner = styled(Spinner)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), height);
var Button = forwardRef(function (props, ref) {
    var type = props.type, _a = props.kind, kind = _a === void 0 ? 'primary' : _a, isLoading = props.isLoading, disabled = props.disabled, _b = props.spinnerSize, spinnerSize = _b === void 0 ? SPINNER_SIZE : _b, spinnerStrokeColor = props.spinnerStrokeColor, _c = props.spinnerMargin, spinnerMargin = _c === void 0 ? 0 : _c, _d = props.as, asTag = _d === void 0 ? 'button' : _d, children = props.children, onClick = props.onClick, tracking = props.tracking;
    var t = useLocalization().t;
    var StyledButton = getStyledButton(kind);
    var handleClick = function (evt) {
        trackButtonClick(tracking);
        onClick === null || onClick === void 0 ? void 0 : onClick(evt);
    };
    return (React.createElement(StyledButton, __assign({}, props, (asTag && { as: asTag }), (!type && asTag && asTag === 'button' && { type: 'button' }), (isLoading && { 'aria-label': t('h24_loading') }), (disabled && { 'aria-disabled': 'true' }), { ref: ref, onClick: handleClick }), isLoading ? (React.createElement(StyledSpinner, { height: "100%", margin: spinnerMargin, size: spinnerSize, strokeColor: spinnerStrokeColor })) : (children)));
});
Button.displayName = 'Button';
var ButtonLink = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textDecoration);
ButtonLink.defaultProps = {
    textDecoration: 'none',
    as: 'a',
};
export { ButtonBase, Button, ButtonLink };
var templateObject_1, templateObject_2;
