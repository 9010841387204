var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { size } from 'polished';
import { alignItems, backgroundColor, borderRadius, boxShadow, color, fontWeight, justifyContent, textAlign, themeGet, } from 'styled-system';
import { font } from '../../theme/helper';
import { themeWidth } from '../../theme/system-utilities';
import { ButtonBase, SPINNER_SIZE } from './Base';
export var ButtonPrimary = styled(ButtonBase)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: ", ";\n\n  ", ";\n\n  &:not(:focus) {\n    outline: none;\n  }\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: ", ";\n\n  ", ";\n\n  &:not(:focus) {\n    outline: none;\n  }\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  ", ";\n"])), color, fontWeight, textAlign, alignItems, justifyContent, boxShadow, borderRadius, backgroundColor, themeWidth, font(3), function (_a) {
    var isLoading = _a.isLoading, disabled = _a.disabled;
    return isLoading ? 'progress' : disabled ? 'not-allowed' : 'pointer';
}, function (props) {
    return props.isLoading && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), themeGet('colors.sys.primary.background.default')(props));
}, function (_a) {
    var spinnerSize = _a.spinnerSize;
    return size(spinnerSize || SPINNER_SIZE);
}, themeGet('colors.sys.neutral.background.default'), function (props) {
    return !props.disabled
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          &:hover,\n          &:focus {\n            color: ", ";\n            background-color: ", ";\n          }\n          &:active {\n            color: ", ";\n            background-color: ", ";\n          }\n        "], ["\n          &:hover,\n          &:focus {\n            color: ", ";\n            background-color: ", ";\n          }\n          &:active {\n            color: ", ";\n            background-color: ", ";\n          }\n        "])), themeGet('colors.sys.neutral.background.default')(props), themeGet('colors.sys.primary.background.hover')(props), themeGet('colors.sys.neutral.background.default')(props), themeGet('colors.sys.primary.background.active')(props)) : css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          color: ", ";\n          background-color: ", ";\n        "], ["\n          color: ", ";\n          background-color: ", ";\n        "])), themeGet('colors.sys.neutral.background.default')(props), themeGet(props.isLoading
        ? 'colors.sys.primary.background.default'
        : 'colors.sys.neutral.background.inverted')(props));
});
ButtonPrimary.defaultProps = __assign(__assign({}, ButtonBase.defaultProps), { p: 'sp_8', color: 'sys.neutral.background.default', fontWeight: 'bold', textAlign: 'center', alignItems: 'center', justifyContent: 'center', boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.15)', borderRadius: 'small', backgroundColor: 'sys.primary.background.default' });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
