var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import { themeGet } from 'styled-system';
export var outlineCSS = function (props) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  outline: 2px solid ", ";\n"], ["\n  outline: 2px solid ", ";\n"])), themeGet('colors.sys.secondary.border.focus')(props)); };
export var focusOutlineCSS = function (props) { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"], ["\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"])), themeGet('colors.sys.secondary.border.focus')(props)); };
export var outlineCSSWithOffset = function (props) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  outline: 2px solid ", ";\n  outline-offset: 2px;\n"], ["\n  outline: 2px solid ", ";\n  outline-offset: 2px;\n"])), themeGet('colors.sys.secondary.border.focus')(props)); };
export var focusOutlineCSSWithOffset = function (props) { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:focus-visible {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n"], ["\n  &:focus-visible {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n"])), themeGet('colors.sys.secondary.border.focus')(props)); };
export var truncateMultilineText = function (numberOfLines) { return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: ", ";\n  overflow: hidden;\n"], ["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: ", ";\n  overflow: hidden;\n"])), numberOfLines); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
